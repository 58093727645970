import React from "react";
import { FaBars, FaUserAlt, FaPlay, FaCreditCard, FaGift, FaSignOutAlt, FaMedal, FaImages, FaHeart, FaCrown, FaHistory } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ASSETS_PATH } from "../../constants/constants.js";
import { COOKIES } from "../../constants/cookies.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import { signOut } from "../../store/actions/user";
import IconCross from "../../Svg/IconCross.js";
import { deleteCookie, getToken, getPurchaseToken } from "../../utilities/cookie.js";
import SubNavbarLinks from "./NavbarLinks/SubNavbarLinks.jsx";
import { MdSubscriptions } from "react-icons/md";
import { translations } from "../../translations/texts.js";
import "./SmartNavbar.scss";
import { useState, useEffect } from "react";
import { googleLogout } from "@react-oauth/google";
import { getUserSubsriptionData } from "../../store/actions/userSubscription.js";

const SmartNavbar = () => {
	const [showUserSidebar, setShowUserSidebar] = useState("");
	const [showMenu, setShowMenu] = useState("");

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);
	const cartItems = useSelector((state) => state.cart.products);
	const user = useSelector((state) => state.user.userData);
	const userSubscription = useSelector((state) => state.nomadsDb.userSubscription);

	useEffect(() => {
		Promise.all([getUserSubsriptionData()])
			.catch((error) => {
				// dispatch(setError(error));
			})
			.finally(() => {
				// setComponentLoading(false);
			});

		return () => {
			// dispatch(removeError);
		};
	}, []);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const openSidebar = () => {
		setShowUserSidebar("--show");
	};

	const closeSidebar = () => {
		setShowUserSidebar("");
	};

	const toggleMenu = () => {
		if (showMenu === "--show") {
			setShowMenu("");
		} else {
			setShowMenu("--show");
			closeSidebar();
		}
	};

	const closeMenu = () => {
		setShowMenu("");
	};

	const logoutUser = () => {
		deleteCookie(COOKIES.TOKEN, getToken());
		deleteCookie(COOKIES.PURCHASETOKEN, getPurchaseToken());
		localStorage.removeItem("cart");
		localStorage.removeItem("_grecaptcha");
		googleLogout();

		dispatch(signOut);
		closeSidebar();
		navigate(INTERNAL_ROUTES.HOME);
	};

	return (
		<header data-testid="container-navbar" className="navbar-container">
			{showMenu && (
				<div className="hamburger-menu-container">
					<SubNavbarLinks isLoggedIn={isLoggedIn} cartItems={cartItems} user={user} openSidebar={openSidebar} closeMenu={closeMenu} />
				</div>
			)}
			<div className="navbar-logo-container-mobile">
				<FaBars onClick={() => toggleMenu()} />
				<Link onClick={() => closeMenu()} to={INTERNAL_ROUTES.HOME}>
					<img className="pngLogo" src={ASSETS_PATH.NOMADS_LOGOS.TEXT_AND_LOGO} alt="Logo" />
				</Link>
			</div>
			<nav className="normal-menu-container">
				<div className="navbar-logo-container-normal">
					<Link to={INTERNAL_ROUTES.HOME}>
						<img className="pngLogo" src={ASSETS_PATH.NOMADS_LOGOS.TEXT_AND_LOGO} alt="Logo" />
					</Link>
				</div>
				<SubNavbarLinks isLoggedIn={isLoggedIn} cartItems={cartItems} user={user} openSidebar={openSidebar} closeMenu={closeMenu} />
			</nav>
			{isLoggedIn && (
				<nav className={"usersidebar-container " + showUserSidebar}>
					<div onClick={closeSidebar} className="usersidebar-close-btn">
						<IconCross />
					</div>
					<Link className="usersidebar-item" to={INTERNAL_ROUTES.USER.PROFILE} onClick={closeSidebar} id="sidebarProfileButton">
						<div className="usersidebar-item-link">
							<FaUserAlt />
							{translations[selectedLanguage].common.sidebarprofile}
						</div>
					</Link>
					<Link
						className="usersidebar-item"
						to={INTERNAL_ROUTES.PAYMENT.MANAGE_PAYMENT_METHODS}
						onClick={closeSidebar}
						id="managePaymentMethodsButton"
					>
						<div className="usersidebar-item-link">
							<FaCreditCard />
							{translations[selectedLanguage].common.updatepaymentprofile}
						</div>
					</Link>
					<Link className="usersidebar-item" to={INTERNAL_ROUTES.HOME} onClick={closeSidebar}>
						<div className="usersidebar-item-link">
							<FaMedal />
							{translations[selectedLanguage].common.purchaseToken}
						</div>
					</Link>
					{user.isSubscribed || userSubscription.code === 500 ? (
						<Link className="usersidebar-item" to={INTERNAL_ROUTES.USER.SUBSCRIPTION_DASHBOARD} onClick={closeSidebar}>
							<div className="usersidebar-item-link">
								<FaCrown />
								{translations[selectedLanguage].common.mySubscription}
							</div>
						</Link>
					) : (
						<Link className="usersidebar-item" to={INTERNAL_ROUTES.USER.SUBSCRIBE} onClick={closeSidebar}>
							<div className="usersidebar-item-link">
								<FaCrown />
								{translations[selectedLanguage].common.subscription}
							</div>
						</Link>
					)}

					<Link className="usersidebar-item" to={INTERNAL_ROUTES.IMAGE_GALLERY} onClick={closeSidebar}>
						<div className="usersidebar-item-link" id="imageDashboardButton">
							<FaImages />
							{translations[selectedLanguage].common.imageGallery}
						</div>
					</Link>

					{user.isSubscribed && userSubscription.id !== 1 && (
						<Link className="usersidebar-item" to={INTERNAL_ROUTES.HISTORY_PAGE} onClick={closeSidebar}>
							<div className="usersidebar-item-link" id="imageDashboardButton">
								<FaHistory />
								{translations[selectedLanguage].common.historyPage}
							</div>
						</Link>
					)}

					<Link className="usersidebar-item" to={INTERNAL_ROUTES.FAVORITES} onClick={closeSidebar}>
						<div className="usersidebar-item-link" id="favoritesButton">
							<FaHeart />
							{translations[selectedLanguage].common.favorites}
						</div>
					</Link>
					<div className="usersidebar-item" onClick={logoutUser}>
						<div className="usersidebar-item-link">
							<FaSignOutAlt />
							{translations[selectedLanguage].common.sidebarlogout}
						</div>
					</div>
				</nav>
			)}
		</header>
	);
};

export default SmartNavbar;
