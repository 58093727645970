import React from "react";
import SubInputError from "../InputError/InputError";

const SubTextInput = ({
  value,
  onChange,
  name,
  placeholder,
  id,
  errorMessage,
  type,
  className,
  onKeyDown
}) => {
  return (
    <>
      <input
        value={value}
        onChange={onChange}
        name={name || id}
        className={`form-input ${className}`}
        placeholder={placeholder}
        id={id}
        type={type || "text"}
        onKeyDown={onKeyDown}
      />
      <SubInputError message={errorMessage} id={id} />
    </>
  );
};

export default SubTextInput;
