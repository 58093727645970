import { isPast } from "date-fns";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NomadsFetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { getLocaleProp } from "../../utilities/strings.js";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading.jsx";
import "./SmartGeneratedImageDashboard.scss";
import { translations } from "../../translations/texts.js";
import SubImageDashboard from "../Common/ImageDashboard/SubImageDashboard.jsx";
import { getToken } from "../../utilities/cookie.js";
import { BiSearchAlt2 } from "react-icons/bi";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";

const SmartGeneratedImageDashboard = () => {
	const [componentLoading, setComponentLoading] = useState(true);
	const [generatedImages, setGeneratedImages] = useState([]);
	const [rentalsHistory, setRentalsHistory] = useState([]);
	const [selectedImageId, setSelectedImageId] = useState(null);
	const [liked, setLiked] = useState(null);
	const [search, setSearch] = useState("");

	const dispatch = useDispatch();
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	useEffect(() => {
		setComponentLoading(true);

		Promise.all([NomadsFetchRequests.nomadsAi.getUserScenes(getToken()), NomadsFetchRequests.nomadsAi.getLikes()])
			.then(([userScenesResponse, likesResponse]) => {
				const sortedData = userScenesResponse.data.sort((a, b) => new Date(a.insertDate) - new Date(b.insertDate));
				setGeneratedImages(sortedData);
				setLiked(likesResponse.data.likes);
			})
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				setComponentLoading(false);
			});

		return () => {
			dispatch(removeError);
		};
	}, []);

	const handleImageSelect = (sceneId) => {
		setSelectedImageId(sceneId);
	};

	const handleUserInput = (e) => {
		setSearch(e.target.value);
	};

	const handleUserSearch = () => {
		setComponentLoading(true)
		Promise.all([NomadsFetchRequests.nomadsAi.getSearchUser(search)])
				.then(([searchResponse]) => {
					setGeneratedImages(searchResponse.data.scenes.slice(0, 50));
				})
				.catch((error) => {
					dispatch(setError(error));
				})
				.finally(() => {
					setComponentLoading(false);
				});
	};

	const renderContent = () => {
		return (
			<>
			<div className="web-store-search-wrap">
				<BiSearchAlt2 className="web-store-search-icon" onClick={() => handleUserSearch()} />
				<div className="web-store-search-input-wrap">
					{" "}
					<SubTextInput
						value={search}
						onChange={handleUserInput}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleUserSearch();
							}
						}}
						name="search"
						placeholder={translations[selectedLanguage].common.search}
					/>
				</div>
			</div>
				<div>
					<h2>{translations[selectedLanguage].generatedImageDashboard.myImages}</h2>
				</div>
				<div>
					{generatedImages.length > 0 && (
						<>
							<div className="rentals-dashboard-rentals">
								{generatedImages.map((rental, index) => {
									let formattedDate;

									if (rental.insertDate) {
										formattedDate = rental.insertDate.split("T")[0];
									}

									const isLiked = liked && liked.some((likedItem) => likedItem.sceneId === rental.sceneId);

									return (
										<div
											key={index}
											className={`rentals-dashboard-rental ${selectedImageId === rental.sceneId ? "isSelected" : ""}`}
											onClick={() => handleImageSelect(rental.sceneId)}
										>
											<SubImageDashboard
												thumbNail={rental.thumbSrc}
												img={rental.imageSrc}
												isSelected={selectedImageId === rental.sceneId}
												id={rental.sceneId}
												createdOn={formattedDate}
												prompt={rental.prompt}
												audioSrc={rental.audioSrc}
												favorite={rental.favourite}
												like={isLiked}
												views={rental.viewCount}
												freetier={rental.freetier}
											/>
										</div>
									);
								})}
							</div>
						</>
					)}
				</div>
			</>
		);
	};

	return (
		<div data-testid="container-rentals-dashboard" className="rentals-dashboard" id="rentals">
			{componentLoading && <SubComponentLoading />}
			
			{!componentLoading && renderContent()}
		</div>
	);
};

export default SmartGeneratedImageDashboard;
